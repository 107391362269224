<template>
    <div class="analyse-home">
        <h2 class="text-secondary" v-html="page.title"/>

        <div class="row mb-5">
            <div class="col-12 col-lg-7 order-1 order-lg-0">
                <div v-html="page.content"/>
            </div>
            <div class="col-12 col-lg-5 order-0 order-lg-1">
                <Video v-if="page.videoEmbed" :embed="page.videoEmbed" />
            </div>
        </div>
    </div>
</template>

<script>
    // @ is an alias to /src
    import Video from '@/components/Video.vue';
    import api from '@/data/api'

    export default {
        name: 'Page',
        components: {
            Video,
        },
        props: {
			pageId: Number
        },
        data() {
            return {
                pageTitle: 'Ontdek per dimensie waar je staat',
                page: {}
            }
        },
        mounted() {
            api.fetchPage(this.$props.pageId).then(response => {
                this.page = response;

                const breadcrumbs = [
                    {
                        'label': 'Home',
                        'path': '/'
                    },
                    {
                        'label': this.page.title,
                    },
                ];
                this.$store.commit('SET_BREADCRUMBS', {breadcrumbs});
            });
        },
        methods: {
            chooseTheme(theme) {
                const _this = this;
                
                this.emitter.emit('choose-role', { 
                    theme: theme, 
                    success() {
                        _this.$router.push({ path: `/analyse/${theme.slug}` });
                    } 
                });
            }
        }
    }
</script>
